import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { MDXLayout as PageLayout } from "../../components/blocks/mdx-layout";
import { SEO } from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="About" description="A little bit about me and what I'm passionate about, what I do at work, and what else I do in my free time. Maybe you're also into photography or hiking?" noIndex mdxType="SEO" />

    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <div className="img-left-wrap-text">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d831eaf0315692c018a1c2b73d8aecf2/ff59c/aman-profile-pic.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFJUlEQVQ4y4XPeUyTdwDG8Soi1xLQ6ZRDbAuMw6Gg4jznxKBsHgGvDHTzIGYo2YIMMiQDZYqKgDBOQaEIBVrgpX1LQXpAS7nvgk5RgQwUBMRNFsElb9/fs0z/WZbI/n3++OT7cNxyZt44C6YZJ+FLxkE8wfAqnjEr5cPMCtUQ43Q5lVnj68t4eXoYvDw92bVr1rCe7u4Gu8xLjNnLRsb8mZwxmaIZ42mKMZoVM/MY4RuOW84M4yyYhpPwJRzE4+BRI7BXTYAfdgn2Fguw2MIMllaWsLGxhouzM1z4PKx2ccFyKgWmv6tgOimF8TQFo1kx5jFC5t8gcRCPEy79nPDFvWQZ34FYmcwnPh/bEL9VtmSXix3x4NoQHp9LVphZkCXBR4j563piMiEhxtMUeR8IrnQMjtQDLPfcgD2OSyE75Y22nwLRdjUEqqhvELXHC/wPLGB5+hAs/tTAZEIC41dzgDzJKLiyIdhu2o7QT3kQBfmg69oZ9Geehz4lHB2XT+GQKxdWEUEwf619B85VyKOewl45jtUBJ/HdWlsc9ODj6t71KDjujSOejsg5sRthW1ZhyZVQmM/Uw/T/wJWSMTjRTxCy/3Nc+NwZ57a5IfuoNxouBiHWbxvEZ/Yh/fBniP4xEJbPq7FwSjb3ZT41AjvlBPaHfI/ywM3QhvlDFeqPoYwIDKRHYDj/IqpO7kbkrfMwmlLC7IV07kIHahj26kk43yhCnM8qZB7ciABPHiL3bkFZiB/U5/wRfewLuHcUY+GUEuaT7wGdi6aIU+k47KlRfFQ9AWvpPWxd746NtlbY4WiNoxtc8OU6Vxxz52Ld6cNYPKEF51klFkzRMP0v6Cp4hZUFfxDrkim4S4bwlUyN8LwY7NjlCrsli/DtFjeE79qA7atd8KHVIiReD4ZmXIWzoyosnaTBmS4n82fFMGKK3oHc3Bn4UL+ROE0dRLXZKBOH4UaSHyIjd8KDuwzO9jZw49qBt8IaBz7hoiA1FI9GaIw/VaF5VIUTU1XEaEYEjqGQ4Tjc/Is5I+lEa4+Q9PTkQaFJRXFFNDJuByMu8SACvF2w2doKO13tcNrLAUkHNkGpSEP3AIW+ITkGhxWYGNOSmhe18JwtZTi++YNMrS4F9a3ZRNN2BwpdFmhlPPJFYUhKC0RM7D4c3+qEqC1OyIrwR5k4Gr2DNHqfVOD+YCX6BuTQD1SSkaEadI+pDJxwYQVTW3cFiqYsoqhPh6ohC/K6BJTLLyA77yySM77G9eQAXA7xBV0TD22XAL2PpNA/odA3QEP/iEZbn4h09kvwcKia5dwqjWOkVT9Dpk4gMlU8quuSoWpMw11tMkoqYlBYGgVJTRwq6+LR00+hpacY+sdSdPeXoutB2VuwWS8kvW83qYEjKo80UPJLkCnjCa26BnltErTtt1DbnAW6JhGUPA7q5kxUaxLQcV+Mxq470D+WoLu/DLpOwT91aNEXksauQjR2CQ2cO8XnDDl5wRBJo4mIjiF3tSlE255DGrsFpEabQarUyaRGl0pkqmtE15FPmnuERP9YQjp+LSG6TgHpflhB1E1ZpK7lNlp7RQaOTHGVLaWjkV/yA37JDkJZZSw0rTeh67iN2qYcKOozoG7OQEVNHNRN2WjqKXx3vbcQda3ZUOgyQCsS3pY3dBSynLyCEIaSx7JKXZohXxTO5hWHssqGVFbdlMHWt+eympZcVtGQyiob0llNaw6r68hlux6Ws233itja5pusoCSCrVQnGnTt+ayuvYD5G5knGR7UtN/dAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Me in a blue t-shirt in front of the beautiful alpes in slovenia at Lake Bled",
              "title": "Me in a blue t-shirt in front of the beautiful alpes in slovenia at Lake Bled",
              "src": "/static/d831eaf0315692c018a1c2b73d8aecf2/ff59c/aman-profile-pic.png",
              "srcSet": ["/static/d831eaf0315692c018a1c2b73d8aecf2/85b06/aman-profile-pic.png 256w", "/static/d831eaf0315692c018a1c2b73d8aecf2/bc282/aman-profile-pic.png 512w", "/static/d831eaf0315692c018a1c2b73d8aecf2/ff59c/aman-profile-pic.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><strong parentName="p">{`Hi, I’m Aman 👋`}</strong><br /><br />{`
Accomplished Product Manager with over 5 years of expertise in `}<em parentName="p">{`driving revenue growth`}</em>{` and `}<em parentName="p">{`enhancing customer experience`}</em>{`. `}<br /><br />{`
Proven success in steering intricate product development projects from conception to launch.
Passionate about leveraging quantitative skills to extract value from data, crafting narratives that resonate with businesses, and driving actionable insights.`}</p>
    </div>
    <h2 {...{
      "id": "what-im-passionate-about"
    }}>{`What I’m passionate about`}</h2>
    <p>{`This personal website initially served as a casual endeavor, where I chronicled my exploration of GitHub repositories that captivated my attention and addressed practical challenges. As I honed my product development expertise, this blog evolved into a repository of my learning experiences. Now, I share my insights, acquired knowledge, and frameworks that fuel my growth and aid in comprehending customer needs while overseeing interdepartmental teams. Inspired by the abundance of freely available open-source content that has enriched my journey, I aspire to reciprocate the knowledge I have gained. 🎉`}</p>
    <h2 {...{
      "id": "hobbies"
    }}>{`Hobbies`}</h2>
    <p>{`When not immersed in resolving customer issues, I find contentment in crafting culinary masterpieces or embarking on invigorating mountain hikes. Additionally, I relish the opportunity to expand my horizons through cultural exploration and cross-cultural interactions. My relocation to Germany has rekindled my passion for beer, a beverage that has become my companion during this extraordinary journey. Within the confines of my abode, I embrace the tranquility of podcasts, the transformative power of painting, and the nurturing touch of tending to my flourishing flora. `}<a parentName="p" {...{
        "href": "https://www.lennyspodcast.com/"
      }}>{`Lenny’s Podcast`}</a>{` stands as my current recommendation for those seeking intellectual stimulation and thought-provoking perspectives.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      